exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-api-beacon-sites-tsx": () => import("./../../../src/pages/api/beacon-sites.tsx" /* webpackChunkName: "component---src-pages-api-beacon-sites-tsx" */),
  "component---src-pages-api-index-tsx": () => import("./../../../src/pages/api/index.tsx" /* webpackChunkName: "component---src-pages-api-index-tsx" */),
  "component---src-pages-datasets-3-uk-3-g-switch-off-tsx": () => import("./../../../src/pages/datasets/3uk-3g-switch-off.tsx" /* webpackChunkName: "component---src-pages-datasets-3-uk-3-g-switch-off-tsx" */),
  "component---src-pages-datasets-ee-sites-tsx": () => import("./../../../src/pages/datasets/ee-sites.tsx" /* webpackChunkName: "component---src-pages-datasets-ee-sites-tsx" */),
  "component---src-pages-datasets-index-tsx": () => import("./../../../src/pages/datasets/index.tsx" /* webpackChunkName: "component---src-pages-datasets-index-tsx" */),
  "component---src-pages-datasets-vodafone-sites-tsx": () => import("./../../../src/pages/datasets/vodafone-sites.tsx" /* webpackChunkName: "component---src-pages-datasets-vodafone-sites-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-software-tsx": () => import("./../../../src/pages/software.tsx" /* webpackChunkName: "component---src-pages-software-tsx" */)
}

