import React from 'react'
import { RecoilRoot } from 'recoil'

import './src/styles/main.less'

export const onClientEntry = () => {
  if (process.env.NODE_ENV !== 'production') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render')
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    })
  }
}

export function wrapRootElement({ element }) {
  return (
    <React.StrictMode>
      <RecoilRoot>{element}</RecoilRoot>
    </React.StrictMode>
  )
}
